<template>
  <div class="cr-card-detail-option" v-if="show">
    <v-icon>
      mdi-map-marker-outline
    </v-icon>

    <div class="cr-edit-wrapper" :class="{ 'py-2': isEdit }">
      <Address
        ref="address"
        v-for="(address, idx) in list"
        :key="idx"
        :address="address"
        :firstItem="idx === 0"
        :lastItem="idx + 1 === list.length"
        @update:value="v => $set(list, idx, v)"
        @click:add="addAddress"
        @click:delete="$delete(list, idx)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters } from "vuex";
import Address from "./Address.vue";

export default {
  components: { Address },
  data({ $store: { getters: g } }) {
    const str = "cardDetail/detail";
    const { addrTypeList, postList, localityList, streetAddressList } = g[str];
    return {
      list:
        addrTypeList?.map((type, idx) => ({
          type,
          post: (postList || [])[idx],
          locality: (localityList || [])[idx],
          streetAddress: (streetAddressList || [])[idx]
        })) || []
    };
  },
  computed: {
    ...mapGetters("cardDetail", ["isEdit", "detail"]),
    show() {
      return true;
      /*
      if (this.isEdit) return true;
      return this.list.length > 0;
       */
    }
  },
  watch: {
    isEdit(isEdit) {
      if (isEdit) {
        if (!this.list?.length) this.list = [{ type: "work" }];
        return;
      }

      const {
        addrTypeList,
        postList,
        localityList,
        streetAddressList
      } = this.detail;
      this.list =
        addrTypeList?.map((type, idx) => ({
          type,
          post: (postList || [])[idx],
          locality: (localityList || [])[idx],
          streetAddress: (streetAddressList || [])[idx]
        })) || [];
    }
  },
  methods: {
    addAddress() {
      this.list = [...this.list, { type: "work" }];
      this.$nextTick(() => {
        const idx = this.list.length - 1;
        this.$refs.address[idx]?.focus();
      });
    },
    getData() {
      if (!this.isEdit) return;

      const addrTypeList = [];
      const postList = [];
      const localityList = [];
      const streetAddressList = [];
      this.list.map(addr => {
        addrTypeList.push(addr.type);
        postList.push(addr.post || "");
        localityList.push(addr.locality || "");
        streetAddressList.push(addr.streetAddress || "");
      });

      return {
        addrTypeList,
        postList,
        localityList,
        streetAddressList
      };
    }
  }
};
</script>
